import React, { Component } from 'react';
import { Navbar, NavItem } from 'react-materialize';

import Brand from './Brand';

class Nav extends Component {
  render() {
    return (
      <Navbar
        brand={<Brand />}
        right
      >
        {/* <NavItem
          onClick={(e) => {
            e && e.preventDefault();
          }}
        >
          My Experience
        </NavItem>
        */}
        <NavItem
          onClick={(e) => {
            e && e.preventDefault();
          }}
        >
          henry@henrysalcedo.com / 520.313.0888
        </NavItem>
      </Navbar>
    );
  }
}

export default Nav;
